import {
  ACCELERATION_LOAN_ROUTE,
  DEBT_PAYOFF_CALCULATOR_ROUTE,
  AUTHORS_ROUTE,
  MXP_INFO_ROUTE,
  MXP_DEBT_CHECKUP_ROUTE,
} from 'constants/navigation'
import { FEATURE_TOGGLES } from 'constants/featureToggles'

/**
 * Get a list of paths that should be disabled based on LaunchDarkly feature flag state
 * @param {object} featureToggleObject
 *   Feature flag object passed through pageProps - @see src/pages/_app.js
 * @returns {string[]|[]}
 */
export const getFeatureDisabledRoutes = (featureToggleObject) => {
  if (!featureToggleObject) {
    // 404 and 500 pages do not have access to LaunchDarkly flags (TODO fix) so disabled routes need
    // to be configured manually here.
    return [...[]]
  }

  return [
    ...(!featureToggleObject[FEATURE_TOGGLES.ACX_WEB_ENABLE_ACCELERATION_LOAN]
      ? [ACCELERATION_LOAN_ROUTE]
      : []),
    ...(!featureToggleObject[FEATURE_TOGGLES.ACX_WEB_ENABLE_DEBT_PAYOFF_CALCULATOR]
      ? [DEBT_PAYOFF_CALCULATOR_ROUTE]
      : []),

    ...(!featureToggleObject[FEATURE_TOGGLES.ACX_WEB_ENABLE_SIGN_IN_MXP] ? [MXP_INFO_ROUTE] : []),

    ...(!featureToggleObject[FEATURE_TOGGLES.ACX_WEB_ENABLE_AUTHORS] ? [AUTHORS_ROUTE] : []),

    ...(!featureToggleObject[FEATURE_TOGGLES.ACX_WEB_ENABLE_MXP_DEBT_CHECKUP]
      ? [MXP_DEBT_CHECKUP_ROUTE]
      : []),
  ]
}

/**
 * Get a list of elements and it's LD condition
 * @param {object} featureToggleObject
 *   Feature flag object passed through pageProps - @see src/pages/_app.js
 * @returns {object}
 */
export const getFeatureDisabledElements = (featureToggleObject) => {
  if (!featureToggleObject) return {}

  const elementList = [
    FEATURE_TOGGLES.ACX_WEB_ENABLE_ALCHEMER_SURVEY,
    FEATURE_TOGGLES.ACX_WEB_ENABLE_NAV_TITLES,
  ]
  let elementToggle = {}

  Object.keys(featureToggleObject).forEach((feature) => {
    if (elementList.includes(feature)) {
      elementToggle[feature] = featureToggleObject[feature]
    }
  })

  return elementToggle
}

export const removePageFromQuery = (query) => {
  if (!query) return

  return Object.keys(query)
    .map((key) => {
      switch (key) {
        case 'page':
          return
        case 'categoryname':
          return
        default:
          return key + '=' + query[key]
      }
    })
    .filter((param) => param !== undefined)
    .join('&')
}

export const getRoutesForProgressPage = [
  '/learn/[categoryname]/[slug]',
  '/a/[slug]',
  '/about/press/[slug]',
]
