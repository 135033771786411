import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import styles from './ConsentBannerBlock.module.scss'
import { Image } from 'components/Image'
import { Button, Typography } from '@achieve/ascend'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import NextLink from 'next/link'

const ConsentBannerBlock = function ({ onClick, bannerCopy }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  return (
    <div data-testid="tcb-banner" className={styles['tcb']}>
      <div className={styles['tcb-card']}>
        <div className={styles['tcb-x']}>
          <TrackingWrapper
            track={{
              list_name: 'Close consent click event',
              nav_link_section: 'TCB banner',
              event_action: 'ui_click',
            }}
            onClick={onClick}
          >
            <Image
              src="/close.svg"
              alt="Close button"
              width={18}
              height={18}
              priority={false}
              aria-hidden={true}
              data-testid="tcb-x-icon"
            />
          </TrackingWrapper>
        </div>

        <Typography
          component="div"
          variant={isMobile ? 'bodyXs' : 'bodyMd'}
          className={styles['tcb-content']}
          data-testid="tcb-text-content"
        >
          <div className={styles['tcb-btn']}>
            <TrackingWrapper
              track={{
                list_name: 'Tracking consent click event',
                nav_link_section: 'TCB banner',
                click_text: 'Ok',
                event_action: 'button_click',
              }}
              onClick={onClick}
            >
              <Button size="small" variant="contained" data-testid="tcb-ok-button">
                <Typography
                  component="div"
                  variant={isMobile ? 'bodyMd' : 'bodyLg'}
                  className={styles['button-padding']}
                  data-testid="tcb-text-content"
                >
                  Ok
                </Typography>
              </Button>
            </TrackingWrapper>
            &nbsp;
          </div>
          {bannerCopy[0]}
          <TrackingWrapper
            track={{
              list_name: 'Policies link click event',
              nav_link_section: 'TCB banner',
              click_text: 'Privacy policy',
              event_action: 'link_click',
            }}
          >
            <NextLink href="/policies#Privacy-Policy" target="blank">
              {bannerCopy[1]}
            </NextLink>
          </TrackingWrapper>
          &nbsp;
          {bannerCopy[2]}
        </Typography>
      </div>
    </div>
  )
}

export { ConsentBannerBlock }
