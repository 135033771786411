import { PRD_ENV } from 'constants/environments'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

/**
 * React hook to check if it's running in prod
 */
const useIsProduction = () => {
  return publicRuntimeConfig?.NEXT_PUBLIC_APP_ENV === PRD_ENV
}

export { useIsProduction }
